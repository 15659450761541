import { Button } from '@material-ui/core';
import styled from 'styled-components/macro';

const StyledEnrollPatientButton = styled(Button)`
  height: 32px;
  width: 145px;
  border-radius: 4px;
  padding: 5px 14px;
  background: #1F2E81;
  :hover {
    background: #384FAC;
  }
  :disabled {
    border: #F5F5F5;
    background: #F5F5F5;
  }
`;

export default StyledEnrollPatientButton;
