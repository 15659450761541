import React from 'react';
import { Text } from 'react-limbix-ui';

const AppVersion: React.FC = () => {
  if (!process.env.APP_VERSION) {
    return null;
  }

  return (
    <Text color="#5E6574" fontSize="12px" textAlign="center" lineHeight="24px">
      {`Version ${process.env.APP_VERSION}`}
    </Text>
  );
};

export default AppVersion;
