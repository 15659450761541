export const range = (start: number, stop: number): Array<number> => (
  Array.from({ length: stop - start }, (_, key) => key + start)
);

export const populateArray = <T, >(length: number, filling: T): Array<T> => (
  Array.from({ length }, () => filling)
);

export const zip = <S, T>(
  a: Array<S>,
  b: Array<T>,
): Array<Array<S | T>> => (
    a.length === b.length ? a.map((k, i) => [k, b[i]]) : null
  );
