import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ToastPayloadType,
} from '@/types/ReduxTypes';

export const defaultToastState = {
  visible: false,
  payload: (null as ToastPayloadType),
};

export type ToastStateType = typeof defaultToastState;

type ShowToastPayload = {
  payload: ToastPayloadType,
};

const toastSlice = createSlice({
  name: 'toast',
  initialState: defaultToastState,
  reducers: {
    showToast: (state, action: PayloadAction<ShowToastPayload>) => {
      const { payload } = action;
      state.visible = true;
      state.payload = payload.payload;
    },
    hideToast: () => ({ ...defaultToastState }),
  },
});

export const { actions, reducer } = toastSlice;
