import { useEffect } from 'react';

import { ApolloError } from '@apollo/client';

import useQuery from '../useQuery';

import { QueryPractitionerNudgesTableArgs, PractitionerNudgesTableType } from '@/apollo/types';
import { OrderByMapType, SortState } from '@/types';
import { PaginationType, SetPaginationType } from '@/types/ReduxTypes';
import {
  DEFAULT_ITEMS_PER_PAGE, INITIAL_TABLE_PAGE, DEFAULT_PAGINATION_TOTAL, PDT_UID,
} from '@/utils/constants';
import { PRACTITIONER_NUDGES_TABLE_QUERY } from '@/apollo/queries';
import { usePagination } from '@/hooks/redux';

const ORDER_BY_MAP: OrderByMapType = {
  patientName: {
    asc: ['patient_last_name', 'patient_first_name'],
    desc: ['-patient_last_name', '-patient_first_name'],
  },
  reason: {
    asc: ['reason'],
    desc: ['-reason'],
  },
  category: {
    asc: ['category'],
    desc: ['-category'],
  },
  triggeredAt: {
    asc: ['triggered_at'],
    desc: ['-triggered_at'],
  },
};

const usePaginatedNudges = (): {
  data: PractitionerNudgesTableType[],
  loading: boolean,
  error: ApolloError,
  refetch: () => void,
  paginationState: PaginationType,
  setPaginationState: SetPaginationType,
} => {
  const defaultSortState: SortState = { columnId: 'triggeredAt', direction: 'desc' };
  const [paginationState, setPaginationState] = usePagination({
    NUDGES_TABLE: {
      total: DEFAULT_PAGINATION_TOTAL,
      currentPage: INITIAL_TABLE_PAGE,
      sortState: defaultSortState,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    },
  });

  const orderBy = paginationState
    ? ORDER_BY_MAP[paginationState.sortState.columnId][paginationState.sortState.direction]
    : ORDER_BY_MAP[defaultSortState.columnId][defaultSortState.direction];

  const {
    data,
    loading,
    error,
    refetch,
  } = useQuery<QueryPractitionerNudgesTableArgs>(
    PRACTITIONER_NUDGES_TABLE_QUERY,
    {
      variables: {
        orderBy,
        pdtUid: PDT_UID,
        limit: paginationState?.itemsPerPage || 20,
        offset: (paginationState?.currentPage * paginationState?.itemsPerPage) || 0,
      },
    },
  );

  useEffect(() => {
    const total = data?.practitionerNudgesTable?.length && data.practitionerNudgesTable[0].total;
    setPaginationState.updateTotal(total);
  }, [data, loading]);

  useEffect(() => {
    refetch();
  }, [paginationState]);

  return {
    data: data?.practitionerNudgesTable,
    loading,
    refetch,
    error,
    paginationState,
    setPaginationState,
  };
};

export default usePaginatedNudges;
