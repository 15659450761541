import React from 'react';
import { Box, Text } from 'react-limbix-ui';

import {
  PrimaryButton,
  SecondaryButton,
} from '../Modal.styles';

import { Warning } from '@/icons';
import { ModalPayloadType } from '@/types/ReduxTypes';

type Props = {
  payload: ModalPayloadType['CONFIRMATION_POPUP'];
  onClose: () => void;
};
const ConfirmationPopup: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const {
    header,
    onConfirm,
    body,
    cancelButtonText,
    confirmButtonText,
  } = payload;

  const handleClickConfirm = () => {
    onConfirm();
  };

  return (
    <Box width="416px" padding="32px" position="relative">
      <Box display="inline-flex">
        <Box margin="0px 10px">
          <Warning height="24px" width="24px" stroke="#DF5F30" />
        </Box>
        <Box display="block">
          <Text fontWeight="700" fontSize="18px" lineHeight="26px" marginBottom="8px">
            {header}
          </Text>
          <Text fontSize="16px" lineHeight="24px" marginBottom="8px">
            {body}
          </Text>
          <Box display="flex" marginTop="24px">
            <Box margin="0 0 0 auto" display="inline-flex">
              <SecondaryButton onClick={onClose}>
                {cancelButtonText || 'Cancel'}
              </SecondaryButton>
              <PrimaryButton onClick={handleClickConfirm}>
                {confirmButtonText || 'Confirm'}
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmationPopup;
