import {
  Button as MuiButton,
} from '@material-ui/core';
import styled from 'styled-components/macro';

const SecondaryButton = styled(MuiButton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  height: 32px;
  background: #FEFEFE;
  border: 1px solid #DADADD;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 4px;
  :hover {
    background: #cccccc;
    border: 1px solid #cccccc;
  }
`;

const PrimaryButton = styled(MuiButton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  height: 32px;
  background: ${(props) => (props.disabled ? '#999999' : '#1F2E81')};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  color: white;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 4px;
  :hover {
    background: #303FA2;
  }
`;

export {
  SecondaryButton,
  PrimaryButton,
};
