import React from 'react';
import { Grid } from '@material-ui/core';
import { Box, Text } from 'react-limbix-ui';

import Styled from './Header.styles';

import { PractitionerType } from '@/apollo/types';
import BigHealthLogo from '@/assets/BigHealthLogo.svg';
import { formatUserName } from '@/utils/stringUtils';
import history from '@/utils/history';
import { EnrollPatientButton } from '@/components/Button';

type Props = {
  practitioner: PractitionerType;
};
const Header: React.FC<Props> = (props: Props) => {
  const { practitioner: { user, organization } } = props;

  return (
    <Styled.AppBar>
      <Styled.Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={6} sm={6}>
            <Box height="88px" alignItems="center">
              <Box
                borderRight="1px solid rgba(0, 0, 0, 0.12)"
                padding="20px"
                width="183px"
                display="flex"
                alignItems="center"
                height="100%"
              >
                <Styled.Logo onClick={() => history.push('/')} src={BigHealthLogo} alt="big health logo" />
              </Box>
            </Box>
          </Grid>
          <Grid item />
          <Grid item xs={6}>
            <Box height="100%" width="100%" display="flex" justifyContent="end" alignItems="center" paddingRight="24px">
              <Box paddingRight="24px">
                <EnrollPatientButton />
              </Box>
              <Box display="block">
                <Text fontSize="18px" lineHeight="26px" fontWeight="600">
                  {formatUserName(user.firstName, user.lastName, '|F| |L|')}
                </Text>
                <Text fontSize="16px" lineHeight="24px" fontWeight="400">
                  {organization.name}
                </Text>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Styled.Toolbar>
    </Styled.AppBar>
  );
};

export default Header;
