import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useActions from './useActions';

import { RootState } from '@/store';
import { TimeUnit, UseUnitsReturnType } from '@/types/ReduxTypes';

const useUnits = (): UseUnitsReturnType => {
  const { units: { updateUnits } } = useActions();
  const units = useSelector((state: RootState) => (state.units));

  const updateTimeUnit = useCallback((timeUnit: TimeUnit) => {
    updateUnits({
      ...units,
      time: timeUnit,
    });
  }, [units]);

  return {
    units,
    updateTimeUnit,
  };
};

export default useUnits;
