import { useSelector } from 'react-redux';

import useActions from './useActions';

import { RootState } from '@/store';
import { SortState } from '@/types/TableTypes';
import { PaginationType, SetPaginationType, PaginationStateType } from '@/types/ReduxTypes';

const usePagination = (initialPagination: PaginationStateType): [PaginationType, SetPaginationType] => {
  const {
    pagination: {
      initializeTable,
      nextPage: nextPageAction,
      previousPage: previousPageAction,
      goToPage: goToPageAction,
      updateSortState: updateSortStateAction,
    },
  } = useActions();

  const tables = useSelector((state: RootState) => (state.pagination));
  const tableId = Object.keys(initialPagination)[0];
  const currentTable = tables[tableId];

  if (!currentTable) {
    initializeTable(initialPagination);
  }

  const updateTotal = (total: number) => {
    if (currentTable && (total || total === 0) && total !== currentTable.total) {
      const tableWithUpdatedTotal = {
        ...currentTable,
        total,
      };
      const paginationState: PaginationStateType = {};
      paginationState[tableId] = tableWithUpdatedTotal;
      initializeTable(paginationState);
    }
  };

  const nextPage = () => {
    nextPageAction({ tableId });
  };
  const previousPage = () => {
    previousPageAction({ tableId });
  };
  const goToPage = (page: number) => {
    goToPageAction({ tableId, page });
  };
  const updateSortState = (sortState: SortState) => {
    updateSortStateAction({ tableId, sortState });
  };

  return [
    currentTable,
    {
      updateTotal,
      nextPage,
      previousPage,
      goToPage,
      updateSortState,
    },
  ];
};

export default usePagination;
