import React, { useCallback, useRef } from 'react';
import {
  Text,
  Box,
  LoadingSpinner,
  Memo,
} from 'react-limbix-ui';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import isEqual from 'react-fast-compare';

import { TableHead, TableBody, PaginationController } from './components';

import { Column, Row, SortState } from '@/types';
import { PaginationType, SetPaginationType } from '@/types/ReduxTypes';
import { calculateSortOrder } from '@/utils/tableUtils';

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`;

const currentSetOfTotal = (pagination: PaginationType): string => {
  const { currentPage, itemsPerPage, total } = pagination;
  const currentSetMin = Math.min((itemsPerPage * currentPage), total);
  const currentSetMax = Math.min(((itemsPerPage * currentPage) + itemsPerPage), total);
  return `${currentSetMin} - ${currentSetMax} of ${total}`;
};

type Props = {
  columns: Column[],
  rows: Row[],
  ariaLabel: string,
  loading?: boolean,
  height?: string,
  minRowHeight?: string,
  pagination: PaginationType,
  setPagination: SetPaginationType,
  onClickRow?: (rowId: string) => void,
  emptyStateBody?: React.ReactNode,
};
const Table: React.FC<Props> = (props: Props) => {
  const {
    columns,
    rows,
    loading,
    ariaLabel,
    height,
    minRowHeight,
    pagination,
    setPagination,
    emptyStateBody,
    onClickRow,
  } = props;

  const divRef = useRef<HTMLDivElement>();

  const handleTableUpdate = useCallback(() => {
    divRef.current.scrollTo(0, 0);
  }, [divRef]);

  const handleClickSortIndicator = useCallback((columnId: string, sortOrder: SortState) => {
    handleTableUpdate();
    const newSortOrder = calculateSortOrder(columnId, sortOrder);
    setPagination.updateSortState(newSortOrder);
  }, []);

  return (
    <Box border="1px solid #F0F0F0">
      <Box ref={divRef} height={height ?? '100%'} width="100%" overflow="auto" borderBottom="1px solid #F0F0F0">
        <StyledTable aria-label={ariaLabel}>
          <TableHead
            columns={columns}
            sortOrder={pagination.sortState}
            onClickSortIndicator={handleClickSortIndicator}
          />
          <TableBody
            rows={rows}
            columns={columns}
            onClickRow={onClickRow}
            minRowHeight={minRowHeight}
          />
        </StyledTable>
        {loading && (
          <Box height="100%" width="100%" display="grid" alignContent="space-around" justifyContent="space-around">
            <LoadingSpinner size="150px" />
          </Box>
        )}
        {(rows.length === 0 && emptyStateBody) && (
          <Box width="100%" alignContent="center">
            {' '}
            {emptyStateBody}
            {' '}
          </Box>
        )}
      </Box>
      <Box height="60px" display="flex" boxShadow="1px">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Text margin="0px 13px">
            {currentSetOfTotal(pagination)}
          </Text>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Box display="inline-flex" margin="14px 17px">
            <PaginationController
              pagination={pagination}
              setPagination={setPagination}
              onTableUpdate={handleTableUpdate}
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default Memo(Table, isEqual);
