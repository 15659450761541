import {
  Button as MuiButton,
  Stepper as MuiStepper,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  withStyles,
} from '@material-ui/core';
import styled from 'styled-components/macro';

const Stepper = styled(MuiStepper)`
  width: clamp(200px, 100%, 500px);
  align-self: center;
  margin: 0px auto;
`;

const SubmitButton = styled(MuiButton)`
  background-color: #089A7E;
  :hover {
    background: #089A7E;
  }
`;

const Radio = withStyles({
  root: {
    '&$checked': {
      color: '#089A7E',
    },
  },
  checked: {},
})(MuiRadio);

const RadioGroup = styled(MuiRadioGroup)`
  flex-direction: row;
`;

export default {
  Stepper,
  SubmitButton,
  Radio,
  RadioGroup,
};
