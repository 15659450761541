import { range } from './arrayUtils';

import {
  Column,
  Cell,
  KeyValuePairType,
  SortState,
  PaginationDisplayType,
} from '@/types';

type ColValueType = 'string' | 'number' | 'date';

export const createColumn = (
  id: string,
  text: string,
  valueType: ColValueType,
  width: string,
  sortable: boolean,
): Column => (
  {
    id,
    text,
    valueType,
    width,
    sortable,
  }
);

export const createCell = (columnId: string, render: KeyValuePairType): Cell => (
  {
    columnId,
    render: render ?? 'None',
  }
);

export const calculateSortOrder = (
  columnId: string,
  sortOrder: SortState,
): SortState => {
  if (sortOrder.columnId === columnId) {
    if (sortOrder.direction === 'desc') {
      return {
        ...sortOrder,
        direction: 'asc',
      };
    }
    const direction = (sortOrder.direction === 'asc' && 'desc') || 'asc';
    return { columnId, direction };
  }
  return { columnId, direction: 'asc' };
};

export const hasSearchString = (stringList: string[], searchString: string): boolean => {
  if (searchString.startsWith('-')) {
    if (searchString.length <= 1) {
      return true;
    }
    const negationString = searchString.slice(1);
    const regExNegated = new RegExp(negationString.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'));
    return stringList.reduce((acc: boolean, curr) => (
      acc && !curr.startsWith(negationString) && !regExNegated.test(curr)
    ), true);
  }
  const regEx = new RegExp(searchString.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'));
  return stringList.reduce((acc: boolean, curr) => (
    acc || curr.startsWith(searchString) || regEx.test(curr)
  ), false);
};

export const calculatePaginationPageDisplay = (currentPage: number, numberOfPages: number): PaginationDisplayType => {
  const pagination: PaginationDisplayType = {
    pages: [],
    hasBreakAtBeginning: false,
    hasBreakAtEnd: false,
  };
  if (numberOfPages < 6) {
    // 1 2 3 4 5
    pagination.pages = range(0, Math.min(numberOfPages || 1, 5));
  } else if (numberOfPages === 6) {
    if (currentPage < 3) {
      // 1 2 3 4 ... 6
      pagination.pages = range(0, 4);
      pagination.hasBreakAtEnd = true;
    } else {
      // 1 ... 3 4 5 6
      pagination.pages = range(2, 6);
      pagination.hasBreakAtBeginning = true;
    }
  } else if (numberOfPages > 6) {
    if (currentPage < 3) {
      // 1 2 3 4 5 ... 7
      pagination.pages = range(0, 5);
      pagination.hasBreakAtEnd = true;
    } else if (currentPage > numberOfPages - 4) {
      // 1 ... 3 4 5 6 7
      pagination.pages = range(numberOfPages - 5, numberOfPages);
      pagination.hasBreakAtBeginning = true;
    } else {
      // 1 ... 3 4 5 ... 7
      pagination.pages = range(currentPage - 1, currentPage + 2);
      pagination.hasBreakAtBeginning = true;
      pagination.hasBreakAtEnd = true;
    }
  }
  return pagination;
};
