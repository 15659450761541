import { useCallback } from 'react';

import useActions from './useActions';

import { DrawerPayloadType, DrawerContentType, UseDrawerReturnType } from '@/types/ReduxTypes';

const useDrawer = (): UseDrawerReturnType => {
  const {
    drawer: {
      showDrawer: showDrawerAction,
      hideDrawer: hideDrawerAction,
    },
  } = useActions();

  const showDrawer = useCallback((drawerType: DrawerContentType, payload: DrawerPayloadType[DrawerContentType]) => {
    showDrawerAction({
      drawerType,
      payload,
    });
  }, []);

  const hideDrawer = useCallback(() => {
    hideDrawerAction();
  }, []);

  return { showDrawer, hideDrawer };
};

export default useDrawer;
