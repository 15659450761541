import {
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem as MuiListItem,
  withStyles,
} from '@material-ui/core';
import styled from 'styled-components/macro';

import { COLORS } from '@/utils/colors';

const SIDE_MENU_WIDTH = 183;

const Drawer = styled(MuiDrawer)`
  width: ${SIDE_MENU_WIDTH}px;
  height: calc(100vh - 88px);
  margin-top: 88px;
  position: relative;
  white-space: nowrap;
`;

const List = styled(MuiList)`
  width: ${SIDE_MENU_WIDTH - 1}px;
  height: calc(100vh - 88px);
  margin-top: 88px;
  position: relative;
  white-space: nowrap;
`;

const PortalTabInterim = withStyles((theme) => ({
  root: {
    height: '56px',
    textDecoration: 'none',
    color: COLORS.gray1,
    alignItems: 'center',
    '&$selected, &$selected:hover': {
      backgroundColor: COLORS.primary6,
      color: COLORS.primary1,
    },
  },
  button: {
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {},
  },
  selected: {
    backgroundColor: COLORS.primary1,
    color: COLORS.white,
  },
}))(MuiListItem);

const PortalTab = styled(PortalTabInterim)<{ $marginTop?: boolean }>`
  margin-top: ${(props) => (props.$marginTop ? '32px' : '0px')};
`;

export default {
  Drawer,
  List,
  PortalTab,
};
