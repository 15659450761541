import { IconButton } from '@material-ui/core';

import { Box } from 'react-limbix-ui';
import React, { useState } from 'react';

import { Close } from '@/icons';
import EnrollPatientForm from '@/components/Form/EnrollPatient';
import usePaginatedPatients from '@/hooks/apollo/queries/usePaginatedPatients';
import { EnrollPatientFormType, GraphQLErrorsType } from '@/types';
import { MutationEnrollPatientArgs } from '@/apollo/types';
import { useMutation } from '@/hooks/apollo';
import { ENROLL_PATIENT } from '@/apollo/mutations';

import { PDT_UID } from '@/utils/constants';

type Props = {
    onClose: () => void;
  };

const EnrollPatientDrawer: React.FC<Props> = (props: Props) => {
  const {
    onClose,
  } = props;
  const { refetch } = usePaginatedPatients();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [enrollPatientMutation] = useMutation<MutationEnrollPatientArgs>(
    ENROLL_PATIENT,
  );
  const [errors, setErrors] = useState<GraphQLErrorsType>();

  const handleSubmit = async (formData: EnrollPatientFormType) => {
    const {
      email,
      firstName,
      lastName,
      phone,
      dateOfBirth,
    } = formData;
    const { data, errors: resultErrors } = await enrollPatientMutation({
      variables: {
        email,
        firstName,
        lastName,
        phone: phone || null,
        dateOfBirth: new Date(dateOfBirth),
        pdtUid: PDT_UID,
      },
    });

    if (resultErrors?.length) {
      setErrors(resultErrors);
    } else if (data?.enrollPatient?.success) {
      setSubmitSuccess(true);
      refetch();
      onClose();
    }
  };

  return (
    <Box width="min(420px, 100vw)">
      <Box width="48px" height="48px" float="right">
        <IconButton
          aria-label="close drawer"
          onClick={onClose}
        >
          <Close width="24px" height="24px" />
        </IconButton>
      </Box>
      <Box padding="40px">
        <EnrollPatientForm onSubmit={handleSubmit} errors={errors} submitSuccess={submitSuccess} />
      </Box>
    </Box>
  );
};

export default EnrollPatientDrawer;
