import { captureException } from '@sentry/react';

type TryCatchType<T, > = {
  result: T,
  error: unknown,
}
export const tryCatch = <T, >(throwableFunction: () => T): TryCatchType<T> => {
  try {
    const result = throwableFunction();
    return {
      result,
      error: null,
    };
  } catch (error) {
    captureException(error);
    return {
      result: null,
      error,
    };
  }
};
