import Login from './Login';
import GetSpark from './GetSpark';
import ResetPassword from './ResetPassword';
import EnrollPatient from './EnrollPatient';

export default {
  Login,
  GetSpark,
  ResetPassword,
  EnrollPatient,
};
