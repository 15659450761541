import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DrawerContentType,
  DrawerPayloadType,
} from '@/types/ReduxTypes';

export const defaultDrawerState = {
  visible: false,
  drawerType: 'NONE' as DrawerContentType,
  payload: (null as DrawerPayloadType[keyof DrawerPayloadType]),
};

export type DrawerStateType = typeof defaultDrawerState;

type ShowDrawerPayload = {
  drawerType: DrawerContentType,
  payload: DrawerPayloadType[DrawerContentType],
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState: defaultDrawerState,
  reducers: {
    showDrawer: (state, action: PayloadAction<ShowDrawerPayload>) => {
      const { payload } = action;
      state.visible = true;
      state.drawerType = payload.drawerType;
      state.payload = payload.payload;
    },
    hideDrawer: () => ({ ...defaultDrawerState }),
  },
});

export const { actions, reducer } = drawerSlice;
