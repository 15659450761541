import React from 'react';
import { Snackbar, SnackbarOrigin } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useSelector } from 'react-redux';

import { RootState } from '@/store';
import { useActions } from '@/hooks/redux';

const Toast: React.FC = () => {
  const { toast: { hideToast } } = useActions();
  const visible = useSelector((state: RootState) => state.toast.visible);
  const payload = useSelector((state: RootState) => state.toast.payload);

  const vertical:SnackbarOrigin['vertical'] = payload?.verticalPosition ?? 'top';
  const horizontal:SnackbarOrigin['horizontal'] = payload?.horizontalPosition ?? 'right';

  if (!visible) {
    return null;
  }
  const title = payload.title
    ?? (
      <AlertTitle>
        {payload.title}
      </AlertTitle>
    );

  return (
    <Snackbar
      open={visible}
      autoHideDuration={6000}
      onClose={hideToast}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert severity={payload.severity} onClose={hideToast}>
        {title}
        {payload.message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
