import { gql } from '@apollo/client';

export const REQUEST_TOKEN_MUTATION = gql`
  mutation RequestToken($email: String!, $password: String!, $clientType: String!) {
    tokenAuth(email: $email, password: $password, clientType: $clientType) {
      token,
      refreshToken,
      user {
        uid,
        emailVerified
      }
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token,
      refreshToken
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      success
    }
  }
`;

export const DISCONTINUE_CARE_MUTATION = gql`
  mutation DiscontinueCare($careteamUid: String!) {
    discontinueCare(careteamUid: $careteamUid) {
      success
    }
  }
`;

export const UPDATE_LOCAL_TIMEZONE = gql`
  mutation UpdateLocalTimezone($localTimezone: String!) {
      updateLocalTimezone(localTimezone: $localTimezone) {
          user {
              localTimezone
          }
      }
  }
`;

export const CREATE_PRACTITIONER_MUTATION = gql`
  mutation CreatePractitioner(
    $email: String!,
    $identifierValue: String!,
    $identifierSystem: String!,
    $identifierState: String,
    $firstName: String!,
    $lastName: String!,
    $phone: String!,
    $preferredContactMethod: String,
    $position: String,
    $organizationName: String!,
    $organizationEmail: String!,
    $organizationPhone: String!,
    $pdtUid: String!,
    $address: String!,
    $postalCode: String!,
    $state: String!,
    $city: String!
    $password: String!,
  ) {
    selfServeCreatePractitioner(
      email: $email,
      identifierValue: $identifierValue,
      identifierSystem: $identifierSystem,
      identifierState: $identifierState,
      firstName: $firstName,
      lastName: $lastName,
      phone: $phone,
      preferredContactMethod: $preferredContactMethod,
      position: $position,
      organizationName: $organizationName,
      organizationEmail: $organizationEmail,
      organizationPhone: $organizationPhone,
      pdtUid: $pdtUid,
      address: $address,
      postalCode: $postalCode,
      state: $state,
      city: $city,
      password: $password,
    ) {
      success
    }
  }
`;

export const ENROLL_PATIENT = gql`
  mutation EnrollPatient(
    $pdtUid: String!,
    $email: String!,
    $firstName: String!,
    $lastName: String!,
    $dateOfBirth: DateTime!
    $phone: String,
  ) {
    enrollPatient(
      pdtUid: $pdtUid,
      email: $email,
      firstName: $firstName,
      lastName: $lastName,
      dateOfBirth: $dateOfBirth,
      phone: $phone,
    ) {
      success
    }
  }
`;

export const SEND_NUDGE = gql`
  mutation SendNudge(
    $nudgeUid: String!, 
    $careteamUid: String!,
  ) {
    sendNudge(
     nudgeUid: $nudgeUid, 
     careteamUid: $careteamUid,
    ) {
      success
    }
  }
`;
