import { gql } from '@apollo/client';

export const PRACTITIONER_QUERY = gql`
  query practitioner {
    practitioner {
      uid
      practitionerRole
      portalAccessTime
      isAdmin
      user {
        firstName
        lastName
      }
      organization {
         name
      }
    }
  }
`;

export const PRACTITIONER_DASHBOARD_TABLE_QUERY = gql`
  query PractitionerDashboardTable($pdtUid: String!, $limit: Int, $offset: Int, $orderBy: [String]) {
    practitionerDashboardTable(pdtUid: $pdtUid, limit: $limit, offset: $offset, orderBy: $orderBy) {
      uid
      total
      patientFirstName
      patientLastName
      patientDateOfBirth
      completedPhqCount
      baselinePhqScore
      baselineQuestionsAnswered
      recentPhqScore
      recentQuestionsAnswered
      programStatusText
      programStatusDate
    }
  }
`;

export const NUDGE_OPPORTUNITY_DRAWER_QUERY = gql`
  query NudgeOpportunity($careteamUid: String!, $nudgeOpportunityUid: String!) {
    nudgeOpportunity(careteamUid: $careteamUid, nudgeOpportunityUid: $nudgeOpportunityUid){
      uid
      providerNotification
      nudgeOpportunityConfig {
        nudgeMessages {
          body(nudgeOpportunityUid: $nudgeOpportunityUid)
          header(nudgeOpportunityUid: $nudgeOpportunityUid)
        }
      }
    }
  }
`;

export const PRACTITIONER_NUDGES_TABLE_QUERY = gql`
  query PractitionerNudgesTable($pdtUid: String!, $careteamUid: String, $limit: Int, $offset: Int, $orderBy: [String]) {
    practitionerNudgesTable(
      pdtUid: $pdtUid, careteamUid: $careteamUid, limit: $limit, offset: $offset, orderBy: $orderBy) {
        total
        uid
        patientFirstName
        patientLastName
        patientDateOfBirth
        reason
        category
        triggeredAt
        providerNotification
        careTeam {
          uid
          lastNudgeSentAt
        }
    }
  }
`;

export const LIBRARY_ITEMS_QUERY = gql`
  query {
    libraryItems {
        title
        link
        category
        subcategory
    }
  }
`;
