import React, { useState } from 'react';
import ReactIdleTimer, { useIdleTimer } from 'react-idle-timer';
import { useSelector } from 'react-redux';

import { IdleTimerPayloadType } from '@/types';
import { RootState } from '@/store';
import { useModal } from '@/hooks/redux';

type Props = {
  idleDuration: number;
  countdownDuration: number;
  onLogout: () => Promise<void> | void;
  devMode?: boolean;
};

const IdleTimer: React.FC<Props> = (props: Props) => {
  const {
    idleDuration,
    countdownDuration,
    onLogout,
    devMode,
  } = props;
  const { showModal, hideModal } = useModal();
  const visible = useSelector((state: RootState) => state.modal.visible);

  const [fireDispatch, setFireDispatch] = useState<boolean>(null);

  const onIdle = () => {
    if (!visible) {
      setFireDispatch(true);
    }
  };

  const checkToken = () => {
    if (!localStorage.getItem('token')) {
      onLogout();
    }
  };

  const {
    isLeader,
    pause,
    reset,
  } = useIdleTimer({
    timeout: idleDuration,
    onIdle,
    onAction: () => {
      reset();
      checkToken();
    },
    eventsThrottle: 500,
    crossTab: {
      emitOnAllTabs: true,
    },
    debounce: 500,
  });

  const stayActive = () => {
    checkToken();
    reset();
  };

  React.useEffect(() => {
    if (fireDispatch) {
      const payload: IdleTimerPayloadType = {
        countdownDuration,
        stayActive,
        isLeader,
        pause,
        onLogout,
      };
      hideModal();
      showModal('IDLE_WARNING', payload);
      setFireDispatch(false);
    }
  }, [fireDispatch]);

  if (devMode) {
    return <></>;
  }

  return <ReactIdleTimer />;
};

export default IdleTimer;
