import { Buffer } from 'buffer';

import { GENDER_MAP, ADHERENCE_SCORE_DATA } from './constants';

import { PractitionerType } from '@/apollo/types';
import { GenderMapKeys } from '@/types';

export const formatPhoneNumber = (phone: string): string => {
  if (!phone) {
    return '-';
  }
  try {
    let phoneNumber = phone;
    if (phoneNumber.startsWith('+1')) {
      phoneNumber = phoneNumber.slice(2);
    }
    if (phoneNumber.length === 10) {
      phoneNumber = `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
    }
    return phoneNumber;
  } catch (error) {
    return phone;
  }
};

export const capitalizeFirstLetter = (string: string): string => {
  if (string.length === 1) {
    return string.charAt(0).toUpperCase();
  }
  if (string.length > 1) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return string;
};

export const formatUserName = (firstName: string, lastName: string, format?: string): string => {
  const lastNameCaps = capitalizeFirstLetter(lastName);
  const firstNameCaps = capitalizeFirstLetter(firstName);
  if (format) {
    try {
      return format.replace(/\|L\|/g, lastNameCaps).replace(/\|F\|/g, firstNameCaps);
    } catch (e) {
      return `${firstNameCaps} ${lastNameCaps}`;
    }
  }
  return `${firstNameCaps} ${lastNameCaps}`;
};

export const formatPractitionerName = (practitioner: PractitionerType): string => {
  let prefix = '';
  let suffix = '';
  if (practitioner.practitionerRole === 'DOCTOR') {
    prefix = 'Dr.';
  }
  if (practitioner.practitionerRole === 'ADMIN') {
    suffix = 'HCA';
  }
  if (practitioner.practitionerRole === 'NURSE') {
    suffix = 'RN';
  }

  prefix = prefix !== '' ? `${prefix} ` : prefix;
  suffix = suffix !== '' ? ` ${suffix}` : suffix;
  const { user: { firstName, lastName } } = practitioner;
  return `${prefix}${firstName} ${lastName}${suffix}`;
};

export const stringToBase64 = (data: string): string => (
  Buffer.from(data).toString('base64')
);

export const base64ToString = (data: string): string => (
  Buffer.from(data, 'base64').toString('ascii')
);

export const genderKeyToValue = (key: GenderMapKeys): string => (GENDER_MAP[key || 'O']);

export const genderValueToKey = (value: string): string => (
  Object.entries(GENDER_MAP).find(([, val]) => value === val)[0]
);

export const getAdherenceScoreData = (score: number): Record<'text' | 'bgcolor' | 'color', string> => {
  try {
    return ADHERENCE_SCORE_DATA[score ?? 0];
  } catch (error) {
    return ADHERENCE_SCORE_DATA[0];
  }
};
