import React from 'react';
import { useForm } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { Link, Box } from 'react-limbix-ui';

import Styled from './ResetPassword.styles';

import { ErrorMessage } from '@/components';
import { ResetPasswordFormType, ResetPasswordFields } from '@/types';

type Props = {
  onSubmit: (formData: ResetPasswordFormType) => Promise<void>;
  errorMessage?: string;
};

const ResetPassword: React.FC<Props> = (props) => {
  const { onSubmit, errorMessage } = props;
  const { register, handleSubmit, watch } = useForm<ResetPasswordFormType>();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        variant="outlined"
        margin="normal"
        type="text"
        required
        fullWidth
        autoFocus
        label="Email Address"
        id={ResetPasswordFields.EMAIL}
        {...register(ResetPasswordFields.EMAIL, { required: true })}
      />
      <Box margin="24px 0px 32px 0px">
        <Link.RouterLink to="/login" underlined>
          return to login
        </Link.RouterLink>
      </Box>
      <ErrorMessage message={errorMessage} placeholderHeight="1.125rem" fontSize="18px" />
      <Styled.SubmitButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={!watch('email')?.length}
      >
        Send reset link
      </Styled.SubmitButton>
    </form>
  );
};

export default ResetPassword;
