import { red } from '@material-ui/core/colors';
// this is done so that material ui plays nice with StrictMode
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import type {} from '@material-ui/lab/themeAugmentation';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1F2E81',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Proxima Nova',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#FEFEFE',
        boxShadow: `-4px -4px 8px rgba(0, 0, 0, 0.06),
                    4px -4px 8px rgba(0, 0, 0, 0.06),
                    -4px 4px 8px rgba(0, 0, 0, 0.06),
                    4px 4px 8px rgba(0, 0, 0, 0.06)`,
        overflow: 'visible',
        maxWidth: null,
      },
      arrow: {
        color: '#FEFEFE',
      },
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#089a7e',
        },
        '&$active': {
          color: '#089a7e',
        },
      },
      active: {},
      completed: {},
    },
    MuiAlert: {
      standardSuccess: {
        backgroundColor: '#EFFBF8',
        color: '#022A23',
        '& $icon': {
          color: '#00B894',
        },
      },
      standardError: {
        backgroundColor: '#FFFAF8',
        color: '#33160B',
        '& $icon': {
          color: '#DF5F30',
        },
      },
      standardInfo: {
        backgroundColor: '#F3F9FF',
        color: '#031D37',
        '& $icon': {
          color: '#1E90FF',
        },
      },
      standardWarning: {
        backgroundColor: '#FEFBF2',
        color: '#372A04',
        '& $icon': {
          color: '#EAAF00',
        },
      },
    },
  },
});

export default theme;
