import { useEffect } from 'react';

import { ApolloError } from '@apollo/client';

import useQuery from '../useQuery';

import { QueryPractitionerDashboardTableArgs, PractitionerDashboardTableType } from '@/apollo/types';
import { OrderByMapType, SortState } from '@/types';
import { PaginationType, SetPaginationType } from '@/types/ReduxTypes';
import {
  DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGINATION_TOTAL, INITIAL_TABLE_PAGE, PDT_UID,
} from '@/utils/constants';
import { PRACTITIONER_DASHBOARD_TABLE_QUERY } from '@/apollo/queries';
import { usePagination } from '@/hooks/redux';

const ORDER_BY_MAP: OrderByMapType = {
  patientName: {
    asc: ['patient_last_name', 'patient_first_name'],
    desc: ['-patient_last_name', '-patient_first_name'],
  },
  status: {
    asc: ['program_status_text', 'program_status_date'],
    desc: ['-program_status_text', '-program_status_date'],
  },
  baseline: {
    asc: ['-baseline_questions_answered', 'baseline_phq_score'],
    desc: ['baseline_questions_answered', '-baseline_phq_score'],
  },
  recent: {
    asc: ['-recent_questions_answered', 'recent_phq_score'],
    desc: ['recent_questions_answered', '-recent_phq_score'],
  },
};

const usePaginatedPatients = (): {
  data: PractitionerDashboardTableType[],
  loading: boolean,
  error: ApolloError,
  refetch: () => void,
  paginationState: PaginationType,
  setPaginationState: SetPaginationType,
} => {
  const defaultSortState: SortState = { columnId: 'patientName', direction: 'asc' };
  const [paginationState, setPaginationState] = usePagination({
    PATIENT_TABLE: {
      total: DEFAULT_PAGINATION_TOTAL,
      currentPage: INITIAL_TABLE_PAGE,
      sortState: defaultSortState,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    },
  });

  const orderBy = paginationState
    ? ORDER_BY_MAP[paginationState.sortState.columnId][paginationState.sortState.direction]
    : ORDER_BY_MAP[defaultSortState.columnId][defaultSortState.direction];

  const {
    data,
    loading,
    error,
    refetch,
  } = useQuery<QueryPractitionerDashboardTableArgs>(
    PRACTITIONER_DASHBOARD_TABLE_QUERY,
    {
      variables: {
        orderBy,
        pdtUid: PDT_UID,
        limit: paginationState?.itemsPerPage || 20,
        offset: (paginationState?.currentPage * paginationState?.itemsPerPage) || 0,
      },
    },
  );

  useEffect(() => {
    const total = data?.practitionerDashboardTable?.length && data.practitionerDashboardTable[0].total;
    setPaginationState.updateTotal(total);
  }, [data, loading]);

  useEffect(() => {
    refetch();
  }, [paginationState]);

  return {
    data: data?.practitionerDashboardTable,
    loading,
    refetch,
    error,
    paginationState,
    setPaginationState,
  };
};

export default usePaginatedPatients;
