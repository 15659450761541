import styled from 'styled-components/macro';
import { Button } from '@material-ui/core';

const SubmitButton = styled(Button)`
  background-color: #1F2E81;
`;

export default {
  SubmitButton,
};
