import { useSelector } from 'react-redux';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';

import useActions from './useActions';

import { RootState } from '@/store';
import { ProviderUserType } from '@/types/ReduxTypes';

const usePermissions = (): [ProviderUserType, ActionCreatorWithOptionalPayload<ProviderUserType>] => {
  const { providerUser: { updateProviderUser } } = useActions();
  const providerUser = useSelector((state: RootState) => (state.providerUser));
  return [providerUser, updateProviderUser];
};

export default usePermissions;
