import { combineReducers, AnyAction } from '@reduxjs/toolkit';

import { reducer as ProviderUserReducer } from './provider';
import { reducer as ModalReducer } from './modal';
import { reducer as PaginationReducer } from './pagination';
import { reducer as UnitsReducer } from './units';
import { reducer as NavigationReducer } from './navigation';
import { reducer as DrawerReducer } from './drawer';
import { reducer as ToastReducer } from './toast';

import { GlobalActionTypes } from '@/types';

const appReducer = combineReducers({
  providerUser: ProviderUserReducer,
  modal: ModalReducer,
  units: UnitsReducer,
  navigation: NavigationReducer,
  pagination: PaginationReducer,
  drawer: DrawerReducer,
  toast: ToastReducer,
});

// eslint-disable-next-line
const rootReducer = (
  state: ReturnType<typeof appReducer>,
  action: AnyAction,
) => {
  if (action.type === GlobalActionTypes.ClearStore) {
    return appReducer(undefined, ({ type: undefined } as never));
  }
  return appReducer(state, (action as never));
};

export default rootReducer;
