import { useCallback } from 'react';

import useActions from './useActions';

import { ToastPayloadType, UseToastReturnType } from '@/types/ReduxTypes';

const useToast = (): UseToastReturnType => {
  const {
    toast: {
      showToast: showToastAction,
      hideToast: hideToastAction,
    },
  } = useActions();

  const showToast = useCallback((payload: ToastPayloadType) => {
    showToastAction({
      payload,
    });
  }, []);

  const hideToast = useCallback(() => {
    hideToastAction();
  }, []);

  return { showToast, hideToast };
};

export default useToast;
