import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Box, Memo, Text } from 'react-limbix-ui';
import isEqual from 'react-fast-compare';
import MuiChevronRight from '@material-ui/icons/ChevronRight';
import MuiChevronLeft from '@material-ui/icons/ChevronLeft';

import { PaginationType, SetPaginationType } from '@/types/ReduxTypes';
import { calculatePaginationPageDisplay } from '@/utils/tableUtils';

const variantStyles = (variant = 'secondary') => (
  {
    primary: css`
      color: #FEFEFE;
      background-color: #1F2E81;
      border: 1px solid #1F2E81;
    `,
    secondary: css`
      cursor: pointer;
      border: 1px solid #0000003B;
      background-color: #FEFEFE;
      color: #1F2E81;
      :not([disabled]):hover {
        background-color: #0000000C;
      }
      :disabled {
        color: #0000003B;
        cursor: default;
      }
    `,
  }[variant]
);

const Button = styled.button<{ variant?: string }>`
  width: 32px;
  height: 32px;
  font-size: 0.875rem;
  border-radius: 4px;
  padding: 0;
  margin: 0px 7px;
  ${({ variant }) => variantStyles(variant)}
`;

const ChevronRight = styled(MuiChevronRight)`
  transform: translate(0px, 1px);
`;

const ChevronLeft = styled(MuiChevronLeft)`
  transform: translate(0px, 1px);
`;

type PaginationButtonProps = {
  isCurrentPage?: boolean,
  onClick: () => void,
};
const PaginationButton: React.FC<PaginationButtonProps> = (props) => {
  const {
    children,
    isCurrentPage,
    onClick,
  } = props;

  return (
    <Button
      variant={isCurrentPage ? 'primary' : 'secondary'}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

const PaginationBreak = () => (
  <Box height="32px" width="32px" margin="0px 7px" display="block" textAlign="center">
    <Text fontSize="24px" lineHeight="20px">
      ...
    </Text>
  </Box>
);

type PaginationControllerProps = {
  pagination: PaginationType,
  setPagination: SetPaginationType,
  onTableUpdate: () => void;
};
const PaginationController: React.FC<PaginationControllerProps> = (props: PaginationControllerProps) => {
  const {
    pagination: {
      total,
      currentPage,
      itemsPerPage,
    },
    setPagination: {
      goToPage,
      previousPage,
      nextPage,
    },
    onTableUpdate,
  } = props;

  const handleGoToPage = useCallback((page: number) => () => {
    onTableUpdate();
    goToPage(page);
  }, []);

  const handlePreviousPage = useCallback(() => {
    onTableUpdate();
    previousPage();
  }, []);

  const handleNextPage = useCallback(() => {
    onTableUpdate();
    nextPage();
  }, []);

  const numberOfPages = Math.ceil(total / itemsPerPage);
  const canGoBack = currentPage > 0;
  const canGoForward = currentPage + 1 < numberOfPages;
  const pagination = calculatePaginationPageDisplay(currentPage, numberOfPages);
  return (
    <Box display="inline-flex">
      <Button
        variant="secondary"
        disabled={!canGoBack}
        onClick={handlePreviousPage}
      >
        <ChevronLeft />
      </Button>
      {pagination.hasBreakAtBeginning && (
        <>
          <PaginationButton onClick={handleGoToPage(0)}>
            1
          </PaginationButton>
          <PaginationBreak />
        </>
      )}
      {pagination.pages.map((value) => (
        <PaginationButton
          key={value}
          isCurrentPage={currentPage === value}
          onClick={handleGoToPage(value)}
        >
          {value + 1}
        </PaginationButton>
      ))}
      {pagination.hasBreakAtEnd && (
        <>
          <PaginationBreak />
          <PaginationButton onClick={handleGoToPage(numberOfPages - 1)}>
            {numberOfPages}
          </PaginationButton>
        </>
      )}
      <Button
        variant="secondary"
        disabled={!canGoForward}
        onClick={handleNextPage}
      >
        <ChevronRight />
      </Button>
    </Box>
  );
};

export default Memo(PaginationController, isEqual);
