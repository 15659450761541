import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SortState, PaginationStateType } from '@/types';

const calculateNumberOfPages = (itemsPerPage: number, total: number) => (
  Math.ceil(total / itemsPerPage) - 1
);

type IteratePagePayloadType = {
  tableId: string,
}

type GoToPagePayloadType = {
  tableId: string,
  page: number,
}

type UpdateSortStatePayloadType = {
  tableId: string,
  sortState: SortState,
}

const paginationSlice = createSlice({
  name: 'pagination',
  initialState: {} as PaginationStateType,
  reducers: {
    initializeTable: (state, action: PayloadAction<PaginationStateType>) => ({
      ...state,
      ...action.payload,
    }),
    nextPage: (state, action: PayloadAction<IteratePagePayloadType>) => {
      const { payload } = action;
      const currentTable = state[payload.tableId];
      const { currentPage, itemsPerPage, total } = currentTable;
      const numberOfPages = calculateNumberOfPages(itemsPerPage, total);
      currentTable.currentPage = currentPage < numberOfPages ? currentPage + 1 : currentPage;
      state[payload.tableId] = currentTable;
    },
    previousPage: (state, action: PayloadAction<IteratePagePayloadType>) => {
      const { payload } = action;
      const currentTable = state[payload.tableId];
      const { currentPage } = currentTable;
      currentTable.currentPage = currentPage > 0 ? currentPage - 1 : currentPage;
      state[payload.tableId] = currentTable;
    },
    goToPage: (state, action: PayloadAction<GoToPagePayloadType>) => {
      const { payload } = action;
      const currentTable = state[payload.tableId];
      const { currentPage, itemsPerPage, total } = currentTable;
      const numberOfPages = calculateNumberOfPages(itemsPerPage, total);
      currentTable.currentPage = (payload.page >= 0 && payload.page <= numberOfPages) ? payload.page : currentPage;
      state[payload.tableId] = currentTable;
    },
    updateSortState: (state, action: PayloadAction<UpdateSortStatePayloadType>) => {
      const { payload } = action;
      const currentTable = state[payload.tableId];
      currentTable.sortState = payload.sortState;
      currentTable.currentPage = 0;
      state[payload.tableId] = currentTable;
    },
  },
});

export const { actions, reducer } = paginationSlice;
