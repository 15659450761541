import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  UnitsType,
} from '@/types/ReduxTypes';

const defaultUnitsTypeState: UnitsType = {
  time: 'Minutes',
};

const unitsSlice = createSlice({
  name: 'units',
  initialState: defaultUnitsTypeState,
  reducers: {
    updateUnits: (state, action: PayloadAction<UnitsType>) => {
      const { payload } = action;
      state.time = payload.time;
    },
  },
});

export const { actions, reducer } = unitsSlice;
