export const COLORS = {
  black: '#202625',
  white: '#FEFEFE',
  gray1: '#4C4D4F',
  gray2: '#76777B',
  gray3: '#9B9CA0',
  gray4: '#BFBFC2',
  gray5: '#DADADD',
  gray6: '#F3F3F3',
  gray7: '#F9F9F9',
  primary1: '#1F2E81',
  primary2: '#384FAC',
  primary3: '#6C7FCF',
  primary4: '#ADBBF8',
  primary5: '#DBE1FC',
  primary6: '#F1F4FE',
  teal1: '#06CEC9',
  teal2: '#82E6E4',
  teal3: '#B4F0EE',
  teal4: '#E6FAF9',
  teal5: '#F5FDFD',
  tealFont: '#027F90',
  coral1: '#FF7F50',
  coral2: '#FFA584',
  coral3: '#FFD8CA',
  coral4: '#FFF3EE',
  coral5: '#FFFAF8',
  coralFont: '#DF5F30',
  violet1: '#9177FD',
  violet2: '#BDADFE',
  violet3: '#DED6FE',
  violet4: '#F4F1FE',
  violet5: '#F7F5FE',
  violetFont: '#6042DE',
  taffyPink1: '#FC9EC6',
  taffyPink2: '#FDC5DD',
  taffyPink3: '#FEE1ED',
  taffyPink4: '#FEF5F9',
  taffyPink5: '#FEF8FB',
  taffyPinkFont: '#D8548C',
  amber1: '#F6C84A',
  amber2: '#FADE92',
  amber3: '#FCEEC8',
  amber4: '#FEF9EC',
  amber5: '#FEFBF2',
  amber6: '#FFF8E5',
  amberFont: '#C69101',
  jade1: '#00B894',
  jade2: '#66D4BF',
  jade3: '#B2E9DE',
  jade4: '#E5F7F4',
  jade5: '#F2FCF9',
  jadeFont: '#089A7E',
  jadeBorder: '#E5F8F4',
  cerulean1: '#1E90FF',
  cerulean2: '#78BCFF',
  cerulean3: '#BBDDFF',
  cerulean4: '#E8F3FF',
  cerulean5: '#F3F9FF',
  ceruleanFont: '#0973DA',
  babyBlue1: '#62C4E8',
  babyBlue2: '#A1DCF1',
  babyBlue3: '#CFEDF8',
  babyBlue4: '#EFF9FC',
  babyBlue5: '#F7FCFD',
  babyBlueFont: '#179ACA',
  trueWhite: '#FFFFFF',
};
